import React from 'react'
import logo from '../../common/images/logo jaune.png'
import "./Navbar.css"

const Navbar = () => {
    return (
        <div>
            <nav className="navbar fixed-top  navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
           
                        <div className="navbar-brand w-50" >
                            <img width="10%" src={logo} alt="baoba" />
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                            </ul>
                            <span className="navbar-text ">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link active group1" style={{fontSize:"1rem"}} aria-current="page" href="#app"> Baoba App</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link group1" href="#world" style={{fontSize:"1rem"}}>Vision</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link group1" href="#about" style={{fontSize:"1rem"}}>About</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link group1" href="#try" style={{fontSize:"1rem"}}>Download</a>
                                    </li>
                                </ul>
                            </span>
                    
                    </div>

                </div>
            </nav>

        </div>
    )
}

export default Navbar
