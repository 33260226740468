import React from 'react';
import './Header.css'
import img from "../../common/img/baoba_logo.png"

const Header = () => {
    return <>
        <div className='header'>
            <div className='container'>
                <div className='row'>
                    <div className='col  d-flex justify-content-center align-center-center'>
                        <div className=' '>

                            <div className='firstTest d-block'>
                            <div className='d-flex justify-content-center'>
                                <img src={img} alt='error' style={{ width: '50%' }} />
                            </div>
                              <div className="w-100 mt-5 display-2"  style={{textAlign: "center"}}> <span> The First Digital Social Ecosystem </span> </div>  
                            </div>
                            <div className='second mt-5 w-100 ' style={{textAlign: "center"}}>
                               <span> We bring social media to the next level</span> 

                            </div>
                            <a href='https://play.google.com/store/apps/details?id=com.thebaobabaoba'>
                                <div className='google mt-4'></div>
                            </a>
                        </div>


                    </div>
                </div>

            </div>
        </div>

    </>;
};

export default Header;
