import React from 'react';
import './footer.css'

const Footer = () => {
    return < div className='footer'>

    <div className='container mb-4 mt-4 p-4'>
        <div className='row'>
            <div className='col-sm-12 col-md-4'>
                <ul>
                    <li><i className="bi bi-app"></i> Baoba App</li>
                    <li><i className="bi bi-bookmark-check"></i> Vision</li>
                    <li><i className="bi bi-building"></i> About</li>
                    <li><i className="bi bi-file-arrow-down"></i> Download</li>
                </ul>
            </div>
            <div className='col-sm-12 col-md-4'>
                <ul>
                    <li><i className="bi bi-body-text"></i> Terms of service</li>
                </ul>
            </div>
            <div className='col-sm-12 col-md-4'>
                <ul>
                    <li><i className="bi bi-geo"></i> Zurich - Switzerland</li>
                    <li><i className="bi bi-envelope"></i> contact@baoba-group.com</li>
                
                </ul>
            </div>

        </div>

    </div>
        <div className="row">
            <div className="col-12">
                <div className=' d-flex justify-content-center align-items-center mb-4'>
                    Copyright © 2022 The Baoba AG. All rights reserved.
                </div>
            </div>
        </div>

    </div>

};

export default Footer;
