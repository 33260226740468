import React from 'react';
import image from "../../common/images/try.png"
import img from "../../common/images/medium.png"

const Try = () => {
    return <div id="try">
        <div className='row'>
            <div className='col app display-1'><i className="bi bi-file-arrow-down"></i> Download</div>
        </div>
        <div className='row'>
            <div className='col-md-6 col-sm-12'>
                <img src={image} width="100%" alt='application' />
            </div>
            <div className='col-md-6 col-sm-12 d-flex justify-content-start align-items-center'>
                <div className='center-text'>
                    <h1 className='num display-2'> Try</h1>
                    <h2 className='title' style={{paddingLeft: "1pc"}}>baoba</h2>
                    <a href='https://play.google.com/store/apps/details?id=com.thebaobabaoba'>
                        <div className='google mt-4'>
                            <img src={img} width="100%" alt='application' />
                        </div>
                    </a>

                </div>
            </div>
        </div>
    </div>;
};

export default Try;
