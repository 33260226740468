import React from 'react';
import content from '../../common/data/About.json'
import "./About.css"
const About = () => {
    return <div id="about">
        <div className='row mt-4'>
            <div className='col app display-1'><i className="bi bi-building"></i> About</div>
        </div>
        <div className='row'>
            {
                content.map(about => (
                    <div className='row' >
                        <div className='col-12 shadow-sm p-3 mb-3 bg-white about' style={{ borderLeft: "5px solid #ffc404" }}>
                            <div className='center-text'>
                                <h2 className='title'>{about.title} </h2>
                                <p className='describe'>
                                        {about.describe}
                                </p>

                            </div>
                        </div>

                    </div>
                ))
            }

          

            {/*content.length > 2 && <div> see more</div>*/}


        </div>
    </div>;
};

export default About;
