import React from 'react';
import './world.css'
import image from '../../common/images/iphone-g2c6873e50_1280 1.jpg'



const World = () => {


    return <div id="world">
        <div className='row'>
            <div className='col app display-1'><i className="bi bi-bookmark-check"></i>  Vision</div>
        </div>
        <div className='row'>
            <div className='col-md-12 col-sm-12'>

                <video controls width="100%">
                    <source src="/static/media/TeaserBaoba.mp4"
                         />
                </video>

            </div>
        </div>

    </div>;
};

export default World;
