import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Appads from "./components/appads/Appads";
import appads_ from "./components/app-ads/App-ads.js"

function App() {
  return (

    <Router>
        <Switch>

            <Route  exact path="/" component={Home}/>
            <Route exact path="/app-ads.txt" component={Appads}/> 
            <Route exact path="/app-ads.txtS" component={appads_}/>
            
        </Switch>

    </Router>



);
}

export default App;
